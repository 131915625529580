import { useRouter } from 'next/router';
import { ReactNode, useRef } from 'react';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { Link } from 'ui/elements/Link';
import {
  AnalyticsEvents,
  event,
  Promotion,
  transformProductToAnalyticsProduct,
} from 'utils/analytics';
import { buildProductUrl } from 'utils/buildProductUrl';
import {
  Product,
  RecommendationsOutput,
  useTrackRecommendationClickMutation,
  Variant,
} from '__generated__/graphql';
import { removeLocalePrefix } from 'utils/locale';
import { useProductClickHandling } from 'hooks/useProductClickHandling';
import { useGA4Events } from 'hooks/useGA4Events';
import { Ga4Item, setGa4ItemInMap } from 'utils/ga4Items';
import { setReferrerCategory } from 'utils/referrerParametersUtils';

export const ProductListItemLink = ({
  master,
  variant,
  children,
  position,
  categoryId,
  itemListId,
  itemListName,
  fromRecommender,
}: {
  master: Product;
  variant: Variant;
  children: ReactNode;
  position: number;
  categoryId?: string;
  itemListId?: string;
  itemListName?: string;
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
}) => {
  const router = useRouter();
  const search = router.pathname.endsWith('/search');
  const { currency, locale } = useSiteConfig();
  const [, trackRecommendationClick] = useTrackRecommendationClickMutation();
  const hasSentAnalytics = useRef(false);
  const t = useTranslate();
  const id = master.id;
  const name = variant.name || master.name;
  const color = variant.colorValue;
  const itemId = `${id}_${color}`;
  const href = buildProductUrl(id, name, {
    ...(search && { search: true }),
    color,
  });

  const categoryUrl = removeLocalePrefix(router.asPath, locale).split('?')?.[0];

  const { setProduct } = useGA4Events();

  // To differentiate in which ProductListItemLink the click occurred.
  const setIsProductClicked = useProductClickHandling(itemId);

  return (
    <Link
      data-test-id="product-list-item-link"
      href={href}
      aria-label={`${t('goTo')} ${master.name} ${variant.colorName}`}
      onClick={e => {
        if (categoryId) setReferrerCategory(categoryId);
        if ((e?.target as HTMLElement).closest('button')) {
          e.preventDefault();
          return;
        }
        if (fromRecommender) {
          trackRecommendationClick({
            masterId: variant.masterId,
            swatch: variant.colorValue,
            recommenderId: fromRecommender.recommenderId,
            recommenderName: fromRecommender.recommenderName,
          });
          event(AnalyticsEvents.PRODUCT_RECOMMENDER_CLICKED, {
            ecommerce: {
              productRecommenderLocation: categoryUrl,
              recommenderName: fromRecommender?.recommenderName,
              productName: name,
              productSku: variant.id,
            },
          });
        }
        hasSentAnalytics.current = true;
        event(AnalyticsEvents.PRODUCT_CLICKED, {
          ecommerce: {
            currencyCode: currency.code,
            click: {
              actionField: {
                list: search ? 'internal search' : categoryUrl,
              },
              products: [
                {
                  ...transformProductToAnalyticsProduct({
                    product: master,
                    variant: variant,
                  }),
                  category: categoryId || master.category?.id || '',
                  list: search ? 'internal search' : categoryUrl,
                  position: position,
                },
              ],
            },
          },
        });
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.PRODUCT_DISCOVERY_CHANNEL,
          event_params: {
            item_id_ep: variant.id,
            item_name_ep: variant.name,
          },
        });
        localStorage.setItem('productClickedPlpUrl', categoryUrl);
        let newGa4Item: Ga4Item = {
          lid: itemListId,
          lname: itemListName,
          idx: position - 1,
        };
        const storedPromotion = window.sessionStorage.getItem(
          'last-clicked-promotion'
        );
        const promotion: Promotion =
          storedPromotion !== null ? JSON.parse(storedPromotion) : undefined;
        if (promotion) {
          newGa4Item = {
            ...newGa4Item,
            cslot: promotion.creativeSlot,
            cname: promotion.creativeName,
            pid: promotion.promotionId,
            pname: promotion.promotionName,
          };
        }
        setGa4ItemInMap(`${id}_${color}`, newGa4Item);
        setProduct(master);
        setIsProductClicked(true);
      }}
    >
      {children}
    </Link>
  );
};
