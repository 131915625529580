import { gql } from 'urql';

import { UrlRedirect } from '__generated__/graphql';

export const URL_REDIRECT_QUERY = gql`
  query UrlRedirect($path: String!) {
    urlRedirectV2(path: $path) {
      availableFrom
      availableTo
      redirectType
      url
    }
  }
`;

export type UrlRedirectQueryResult = {
  urlRedirectV2: UrlRedirect;
};

export type UrlRedirectQueryOptions = {
  asPath: string;
  pause: boolean;
};
