import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { FilterOption } from '__generated__/graphql';
import {
  decodeFilters,
  encodeFilterName,
  encodeFilters,
  isValidFilter,
  QueryStringBasedFilters,
} from 'utils/productFilters';
import { AnalyticsEventValues, event as analyticsEvent } from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { dySortItemsEvent } from 'utils/dynamicYield';
import {
  formatDefaultPriceRange,
  getDefaultPriceRange,
  paramsOrPriceRange_Max,
  paramsOrPriceRange_Min,
  PriceRange,
  validatePriceFilterLabel,
} from 'utils/priceFilter';
import { wellKnownFilterIds } from 'utils/products';

import { ProductListProps } from './ProductList';

type AnalyticsEvent = {
  event: AnalyticsEventValues;
  params: {
    domevent: string;
    domlabel: string;
  };
};
const priceRangeInitialState = { max: '', min: '' };

const ProductListFilterContext = createContext<{
  queryParams: { [key: string]: string };
  enabledFilterOptions: { [key: string]: FilterOption };
  enabledFilters: string[];
  setSortOrder: (value: string) => void;
  setFilter: (params: QueryStringBasedFilters) => void;
  clearFilters: (filters: string[]) => void;
  removeFilterValue: (name: string, value: string) => void;
  clearAllFilters: () => void;
  dispatchAnalyticsEvent: (event: AnalyticsEvent) => void;
  totalCount: number;
  isFetching: boolean;
  noResults: boolean;
  priceRange: PriceRange;
  setPriceRange: React.Dispatch<React.SetStateAction<PriceRange>>;
  setFilterItemsCache: React.Dispatch<React.SetStateAction<FilterOption[]>>;
  filterItemsCache: FilterOption[];
  defaultPriceRange: PriceRange;
  queryParamsPriceRange: number[];
}>({
  queryParams: {},
  enabledFilterOptions: {},
  enabledFilters: [],
  setSortOrder: () => null,
  setFilter: () => null,
  removeFilterValue: () => null,
  clearFilters: () => null,
  clearAllFilters: () => null,
  dispatchAnalyticsEvent: () => null,
  totalCount: 0,
  isFetching: false,
  noResults: false,
  priceRange: priceRangeInitialState,
  setPriceRange: () => null,
  filterItemsCache: [],
  setFilterItemsCache: () => null,
  defaultPriceRange: priceRangeInitialState,
  queryParamsPriceRange: [],
});

export const ProductListFilterProvider: React.FC<ProductListProps> = ({
  noResults,
  filteringOptions,
  totalCount,
  isFetching,
  children,
}) => {
  const { resetSearchConditionsOptions, staticFeatures } = useSiteConfig();
  const router = useRouter();
  const routeShards = router.asPath.split('?');
  const queryString = routeShards?.[1] || '';
  const newQueryParams = useMemo(
    () => new URLSearchParams(queryString),
    [queryString]
  );

  const [priceRange, setPriceRange] = useState<PriceRange>(
    priceRangeInitialState
  );
  const [filterItemsCache, setFilterItemsCache] = useState(filteringOptions);

  const minMaxPriceFilterOption = filterItemsCache.filter(
    item => item.id === wellKnownFilterIds.minMaxPriceRange
  )[0];

  const [defaultPriceRange, setDefaultPriceRange] = useState(
    getDefaultPriceRange(minMaxPriceFilterOption)
  );
  const [defaultValueClearPriceFilter] = useState(
    getDefaultPriceRange(minMaxPriceFilterOption)
  );

  const queryParams = useMemo(() => {
    return Array.from(newQueryParams).reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
  }, [newQueryParams]);

  const validFilters = decodeFilters(queryParams);
  const enabledFilters = Object.keys(validFilters).reduce((result, param) => {
    if (param === 'minPrice' || param === 'maxPrice') {
      if (result.includes('price')) return result;
      result.push('price');
    } else {
      result.push(param);
    }
    return result;
  }, [] as string[]);

  const enabledFilterParams = enabledFilters.reduce((result, key) => {
    if (key === 'price') {
      result[key] = [
        `(${validFilters['minPrice']}..${validFilters['maxPrice']})`,
      ];
    } else {
      result[key] = validFilters[key].split(',');
    }
    return result;
  }, {});

  const queryParamsPriceRange = formatDefaultPriceRange(
    enabledFilterParams?.['price']?.[0]
  );

  // save the filtering options to use when the total count is 0 and ther's no filters on the query response.
  useEffect(() => {
    if (totalCount === 0) return;
    setFilterItemsCache(filteringOptions);
  }, [filteringOptions, totalCount]);

  // sets the default initial price range and after filters changed (size, color)
  useEffect(() => {
    setDefaultPriceRange(getDefaultPriceRange(minMaxPriceFilterOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(minMaxPriceFilterOption)]);

  // we use the query params as source of truth but only when there's of the priceSlider, but only if is not out of the default range
  useEffect(() => {
    const priceMin = paramsOrPriceRange_Min({
      queryParamsPriceRange,
      priceRange,
    });
    const priceMax = paramsOrPriceRange_Max({
      queryParamsPriceRange,
      priceRange,
    });

    const priceRangeIsOutOfTheDefaultRange =
      priceMax > Number(defaultPriceRange.max || 0) ||
      priceMin < Number(defaultPriceRange.min || 0);

    if (queryParamsPriceRange && !priceRangeIsOutOfTheDefaultRange) {
      setPriceRange({ min: String(priceMin), max: String(priceMax) });
    } else {
      setPriceRange(defaultPriceRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, JSON.stringify(defaultPriceRange)]);

  const minMaxPriceRangeValue =
    minMaxPriceFilterOption?.values[minMaxPriceFilterOption.values.length - 1];
  const isPriceLabelAndParamsMatching = validatePriceFilterLabel(
    minMaxPriceRangeValue?.label,
    enabledFilterParams?.['price']?.[0]
  );

  const enabledFilterOptions: {
    [key: string]: FilterOption;
  } = filterItemsCache
    .filter(v => v !== null)
    .reduce((result, filter) => {
      if (!enabledFilters.includes(filter.id)) return result;
      result[filter.id] = {
        id: filter.id,
        label: filter.label,
        values:
          filter.id === 'price' &&
          minMaxPriceFilterOption &&
          isPriceLabelAndParamsMatching
            ? [minMaxPriceRangeValue]
            : enabledFilterParams[filter.id]
                .map(
                  v => filter.values.find(({ value }) => value === v) || false
                )
                .filter(Boolean),
      };

      return result;
    }, {});

  const clearAllFilters = useCallback(() => {
    const routePath = routeShards[0];
    setPriceRange(defaultValueClearPriceFilter);
    // dont clear params like sort, search query and offset
    for (const [key] of Array.from(newQueryParams)) {
      if (isValidFilter(key)) {
        newQueryParams.delete(key);
      }
    }

    // convert to string
    const newQueryParamsStr = newQueryParams.toString();
    const routeParams =
      newQueryParamsStr.length > 0 ? `?${newQueryParamsStr}` : '';

    // replace route
    router.replace(`${routePath}${routeParams}`, undefined, {
      scroll: resetSearchConditionsOptions?.isRouterScrollEnabled ?? false,
    });
  }, [
    routeShards,
    defaultValueClearPriceFilter,
    newQueryParams,
    router,
    resetSearchConditionsOptions?.isRouterScrollEnabled,
  ]);

  const dispatchAnalyticsEvent = ({ event, params }: AnalyticsEvent) => {
    analyticsEvent(event, params);
  };

  const setSortOrder = useCallback(
    (value: string) => {
      if (staticFeatures?.injectDynamicYieldScripts) {
        dySortItemsEvent(value);
      }

      const { offset: _o, ...nonOffsetQuerystrings } = router.query;
      const query = { ...nonOffsetQuerystrings, sort: value };
      router.push({ query }, undefined, { scroll: false });
    },
    [staticFeatures, router]
  );

  const setFilter = useCallback(
    (params: QueryStringBasedFilters) => {
      const { offset: _o, ...nonOffsetQuerystrings } = router.query;
      const encodedFilters = encodeFilters(params);
      const query = { ...nonOffsetQuerystrings, ...encodedFilters };
      router.push({ query }, undefined, { scroll: false });
    },
    [router]
  );

  const removeFilterValue = (filter, value) => {
    let filterQueryParams: string[] = [];

    if (filter === 'price') {
      clearFilters(['minPrice', 'maxPrice']);
      return;
    } else {
      const encodedFilterName = encodeFilterName(filter);
      filterQueryParams = queryParams[encodedFilterName].split(',');
      const valueIndex = filterQueryParams.indexOf(value);
      filterQueryParams.splice(valueIndex, 1);
    }

    if (filterQueryParams.length === 0) {
      clearFilters([filter]);
    } else {
      setFilter({
        [filter]: filterQueryParams.join(','),
      });

      dispatchAnalyticsEvent({
        event: 'refinement selected',
        params: {
          domevent: filter,
          domlabel: filterQueryParams.join('|'),
        },
      });
    }
  };

  const clearFilters = useCallback(
    (filters: string[]) => {
      const { offset: _o, ...nonOffsetQuerystrings } = router.query;
      for (const key of filters) {
        const encodedKey = encodeFilterName(key);
        delete nonOffsetQuerystrings[encodedKey];
      }
      router.push({ query: nonOffsetQuerystrings }, undefined, {
        scroll: false,
      });
    },
    [router]
  );

  return (
    <ProductListFilterContext.Provider
      value={{
        queryParams,
        enabledFilterOptions,
        removeFilterValue,
        enabledFilters,
        clearAllFilters,
        clearFilters,
        setFilter,
        setSortOrder,
        dispatchAnalyticsEvent,
        totalCount,
        isFetching,
        noResults,
        priceRange,
        setPriceRange,
        defaultPriceRange,
        queryParamsPriceRange,
        filterItemsCache,
        setFilterItemsCache,
      }}
    >
      {children}
    </ProductListFilterContext.Provider>
  );
};

export const useProductListFilterContext = () =>
  useContext(ProductListFilterContext);
