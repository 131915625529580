import { gql } from 'urql';

export const PAGINATED_OUTPUT_NODES_FRAGMENT = gql`
  fragment PaginatedOutputNodesFragment on PaginatedOutput {
    nodes {
      __typename
      id
      color
      masterId
      variantProduct {
        ...mandatoryVariantFields
        badges {
          id
          label
        }
        percentageDiscountBadge
        salePrice
        productPrice {
          price
          salePrice
          promotionPrice
          isSalePriceElapsed
        }
        displayOutOfStock {
          soldout
          comingsoon
          backsoon
          presale
          displayValue
        }
        orderable
        promotions(page: ProductListingPage) {
          id
          calloutMessage
        }
        isAppExclusive
        appOnlyDateTimeFrom
        appOnlyDateTimeTo
      }
      masterProduct {
        ...mandatoryMasterFields
        brand
        promotions(page: ProductListingPage) {
          id
          calloutMessage
        }
        score {
          rating
          amount
        }
      }
    }
  }
`;

export const PAGINATED_OUTPUT_METADATA_FRAGMENT = gql`
  fragment PaginatedOutputMetadataFragment on PaginatedOutput {
    sortingOptions {
      id
      label
    }
    selectedSort
    filteringOptions {
      id
      label
      values {
        value
        label
        hitCount
      }
    }
    totalCount
  }
`;
