import { NextRouter } from 'next/router';

import { createListVariables } from 'utils/products';
import {
  FilterInputOption,
  ProductSearchExpansion,
} from '__generated__/graphql';

export type CommonQueryOptions = {
  router: NextRouter;
  offset: number;
  includeMetadata?: boolean;
  pause?: boolean;
};

export type CommonQueryVariables = {
  limit?: number | null;
  offset?: number | null;
  sort?: string | null;
  filters: FilterInputOption[];
  expansions?: ProductSearchExpansion[] | null;
};

export const commonQueryVariables = ({
  router,
  offset,
}: CommonQueryOptions): CommonQueryVariables => ({
  ...createListVariables(router.query),
  offset,
});
