import { InputRadio } from '@global-ecom/nitro-uds/elements';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { dyFilterEvent } from 'utils/dynamicYield';
import { formatDefaultPriceRange, handleClearFilter } from 'utils/priceFilter';
import { FilterOption } from '__generated__/graphql';

import {
  ButtonClearFilter,
  triggerGA4CustomEvent,
} from './ProductListFilterDropdown';
import { useProductListFilterContext } from './ProductListFilterProvider';

export const PriceFilter: React.FC<FilterOption> = props => {
  const {
    enabledFilterOptions,
    dispatchAnalyticsEvent,
    setFilter,
    clearFilters,
  } = useProductListFilterContext();
  const { staticFeatures } = useSiteConfig();

  const handlePriceChange = e => {
    const [minPrice, maxPrice] = formatDefaultPriceRange(
      e.target.value
    ) as number[];

    dispatchAnalyticsEvent({
      event: 'refinement selected',
      params: {
        domevent: 'price',
        domlabel: `pmax:${maxPrice},pmin:${minPrice}`,
      },
    });

    if (staticFeatures.injectDynamicYieldScripts) {
      const filterProps = `pmax:${maxPrice},pmin:${minPrice}`;
      dyFilterEvent('price', filterProps);
    }
    setFilter({
      minPrice: String(minPrice),
      maxPrice: String(maxPrice),
    });
    triggerGA4CustomEvent(
      props.id.toLowerCase(),
      `${props.id.toLowerCase()}:${minPrice}-${maxPrice}`
    );
  };

  const selectedValue =
    enabledFilterOptions['price']?.values[0]?.value || undefined;

  return (
    <div className="flex flex-col items-start">
      {selectedValue ? (
        <div>
          <ButtonClearFilter
            onClick={() => handleClearFilter(props, clearFilters)}
          />
        </div>
      ) : null}
      <div className="whitespace-nowrap w-auto">
        <div className="space-y-3" data-test-id={`${props.id}-filter-values`}>
          {props.values
            // Show prices that have product hitCount
            .filter(({ hitCount }) => hitCount !== 0)
            .map(({ value, label }, idx) => (
              <InputRadio
                autoFocus={selectedValue === value || idx === 0}
                name="price"
                key={value}
                id={value}
                value={value}
                checked={selectedValue === value}
                onChange={handlePriceChange}
              >
                <span className="text-puma-black">{label}</span>
              </InputRadio>
            ))}
        </div>
      </div>
    </div>
  );
};
