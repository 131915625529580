import React from 'react';

import { isNotNullish } from 'utils/isNotNullish';
import {
  Maybe,
  ProductPromotion as ProductPromotionType,
  Variant,
} from '__generated__/graphql';

import { SafeHtml } from '../elements/SafeHtml';

const ProductPromotion: React.FC<{
  promotion: Maybe<ProductPromotionType>;
}> = props =>
  props.promotion?.calloutMessage ? (
    <SafeHtml
      html={props.promotion.calloutMessage}
      as="p"
      className="text-red-600 font-bold"
      data-test-id="promotion-callout-message"
    />
  ) : null;

export const ProductPromotions: React.FC<{
  variant?: Maybe<Variant>;
  compact?: boolean;
}> = props => {
  const promotions = props.variant?.promotions?.filter(isNotNullish);
  const displayOutOfStock = props.variant?.displayOutOfStock;

  return props.variant?.orderable &&
    !displayOutOfStock?.comingsoon &&
    promotions?.length ? (
    <div className={props.compact ? 'text-xs' : 'space-y-2'}>
      {promotions.map(promo => (
        <ProductPromotion key={promo.id} promotion={promo} />
      ))}
    </div>
  ) : null;
};
