import { useEffect, useState } from 'react';
import { tw } from 'twind';
import { Button } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';

export const ScrollToTopButton = () => {
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () =>
    window.pageYOffset > 300 ? setVisible(true) : setVisible(false);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div
      className={tw(
        `flex justify-center items-center fixed z-10 bottom-3 right-4 h-12 w-12`,
        visible &&
          `focus-within:rounded-1/2 focus-within:border-2 focus-within:border-solid focus-within:border-puma-black-400`
      )}
    >
      {visible && (
        <Button
          onClick={scrollToTop}
          label={t('backToTop')}
          className={tw(
            `flex justify-center items-center bg-white rounded-1/2 h-10 w-10 border-1 border-puma-black-500`,
            `md:hover:bg-puma-black-600 animate-fade-in transition-colors duration-200`,
            `focus:rounded-1/2 focus:outline-none focus:shadow-[0px_0px_0px_2px_#8C9198] focus:border-0`
          )}
          icon="chevron-up"
          variant="secondary"
          mode="icon"
        />
      )}
    </div>
  );
};
