import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, style, tw } from 'twind/style';
import { Icon, InputCheckbox } from '@global-ecom/nitro-uds/elements';

import { FilterOption } from '__generated__/graphql';
import { useDialogDismiss, useFocusTrap } from 'hooks/a11y';
import { getFocusTargets } from 'hooks/a11y/focus';
import { useTranslate } from 'hooks/useTranslations';
import { mapLabel } from 'utils/mapColourFilterLabel';
import { wellKnownFilterIds } from 'utils/products';
import { useProductListFilterContext } from 'ui/components/ProductListFilterProvider';
import { dyFilterEvent } from 'utils/dynamicYield';
import { AnalyticsEvents, event } from 'utils/analytics';

import { useSiteConfig } from '../../hooks/useSiteConfig';

import CloseButton from './CloseButton';
import PriceFilterSlider from './PriceFilterSlider';
import { PriceFilter } from './priceFilter';

export const focusOnFirst = props => {
  const parent = document.activeElement?.closest(
    `#${props.id}-filter-dropdown`
  ) as HTMLElement;

  if (!parent) return;

  const focusTargets = getFocusTargets(parent);
  // Focus on the second item in the array since the Clear
  // button has not been removed yet
  focusTargets[1].focus();
};

export const triggerGA4CustomEvent = (
  refinement_attribute,
  refinement_selected
) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.GA4EC_Refinement,
    event_params: {
      refinement_attribute: refinement_attribute,
      refinement_selected: refinement_selected,
    },
  });
};

const ColorFilter: React.FC<FilterOption> = props => {
  const {
    enabledFilterOptions,
    dispatchAnalyticsEvent,
    setFilter,
    clearFilters,
  } = useProductListFilterContext();

  const { colors, staticFeatures } = useSiteConfig();
  const selectedOptions = (enabledFilterOptions[props.id]?.values || []).map(
    v => v.value
  );

  const optionsSet = new Set(selectedOptions);

  const handleColorClick = useCallback(
    e => {
      e.stopPropagation();
      const value = e.currentTarget.dataset.value;
      if (optionsSet.has(value)) {
        optionsSet.delete(value);
      } else {
        optionsSet.add(value);
      }

      if (optionsSet.size > 0) {
        setFilter({
          [props.id]: Array.from(optionsSet).join(','),
        });
        triggerGA4CustomEvent(
          props.id.toLowerCase(),
          `${props.id.toLowerCase()}:${value.toLowerCase()}`
        );
      } else {
        clearFilters([props.id]);
      }

      if (staticFeatures.injectDynamicYieldScripts && optionsSet.has(value)) {
        dyFilterEvent('color', value);
      }

      dispatchAnalyticsEvent({
        event: 'refinement selected',
        params: {
          domevent: props.id,
          domlabel: Array.from(optionsSet).join('|'),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsSet, props.id]
  );

  const handleClearFilter = () => {
    focusOnFirst(props);
    clearFilters([props.id]);
  };

  const columns = Math.min(props.values.length, 4);

  return (
    <div className="flex flex-col items-start whitespace-nowrap w-auto">
      {optionsSet.size ? (
        <div>
          <ButtonClearFilter onClick={handleClearFilter} />
        </div>
      ) : null}
      <div
        role="group"
        className={tw('w-full grid gap-4 grid-cols-3', {
          desktop: css({
            gridTemplateColumns: `repeat(${columns}, minmax(5.2rem, 1fr))`,
          }),
        })}
        data-test-id={`${props.id}-filter-values`}
      >
        {props.values.map(({ value, label, hitCount }, idx) => {
          const color =
            colors && colors[value.toLowerCase()]
              ? colors[value.toLowerCase()]
              : value.toLowerCase();

          return (
            <button
              key={value}
              autoFocus={idx === 0}
              aria-pressed={optionsSet.has(value)}
              data-value={value}
              data-test-id={`${value}-btn`}
              className={tw(
                'group focus:outline-none',
                { 'text-white': color !== 'white' },
                'hover:text-puma-black-300 text-center whitespace-nowrap flex flex-col items-center justify-center space-y-2'
              )}
              onClick={handleColorClick}
              type="button"
            >
              <span
                className={`inline-flex justify-center items-center border w-9 h-9 rounded-full border border-puma-black overflow-hidden ${
                  color === 'metallic'
                    ? 'bg-gradient-to-tr from-gray-600 via-gray-300 to-gray-600'
                    : `bg-swatch-${color}`
                }`}
                style={{
                  backgroundImage:
                    color === 'multi-colored' || color === 'assorted colours'
                      ? 'linear-gradient(to right top, red, orange, yellow, green, blue, violet)'
                      : undefined,
                }}
              >
                {optionsSet.has(value) ? (
                  <span className="absolute group-hover:opacity-80">
                    <Icon name="check" size="xl" />
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span
                className={`block text-puma-black text-center text-sm leading-tight whitespace-nowrap capitalize`}
              >
                {mapLabel(label)} ({hitCount})
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const CheckboxFilter: React.FC<FilterOption> = props => {
  const {
    enabledFilterOptions,
    dispatchAnalyticsEvent,
    setFilter,
    clearFilters,
  } = useProductListFilterContext();
  const { staticFeatures } = useSiteConfig();

  const selectedOptions = (enabledFilterOptions[props.id]?.values || []).map(
    v => v.value
  );

  const optionsSet = new Set(selectedOptions);

  const handleCheckboxChange = useCallback(
    e => {
      if (e.target.checked) {
        optionsSet.add(e.target.value);
        triggerGA4CustomEvent(
          props.label.toLowerCase(),
          `${props.label.toLowerCase()}:${e.target.value.toLowerCase()}`
        );
      } else {
        optionsSet.delete(e.target.value);
      }

      if (optionsSet.size > 0) {
        setFilter({
          [props.id]: Array.from(optionsSet).join(','),
        });
      } else {
        clearFilters([props.id]);
      }

      if (staticFeatures.injectDynamicYieldScripts && e.target.checked) {
        dyFilterEvent(props.label, e.target.value);
      }

      dispatchAnalyticsEvent({
        event: 'refinement selected',
        params: {
          domevent: props.id,
          domlabel: Array.from(optionsSet).join('|'),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsSet]
  );

  const handleClearFilter = () => {
    focusOnFirst(props);
    clearFilters([props.id]);
  };

  return (
    <div className="flex flex-col items-start whitespace-nowrap w-auto">
      {selectedOptions.length ? (
        <div>
          <ButtonClearFilter onClick={handleClearFilter} />
        </div>
      ) : null}
      <div
        role="group"
        className="space-y-3"
        data-test-id={`${props.id}-filter-values`}
      >
        {props.values.map(({ value, label, hitCount }, idx) => (
          <InputCheckbox
            autoFocus={idx === 0}
            key={value}
            value={value}
            id={`${props.id}-${value}`}
            checked={selectedOptions.includes(value)}
            onChange={handleCheckboxChange}
          >
            <span className="text-puma-black text-base">{`${label}`}</span>
            <span className="ml-1 tracking-widest text-puma-black-300 text-base">
              [{`${hitCount}`}]
            </span>
          </InputCheckbox>
        ))}
      </div>
    </div>
  );
};

const SizeFilter: React.FC<FilterOption> = props => {
  const {
    enabledFilterOptions,
    dispatchAnalyticsEvent,
    setFilter,
    clearFilters,
  } = useProductListFilterContext();
  const { staticFeatures } = useSiteConfig();

  const selectedOptions = (enabledFilterOptions[props.id]?.values || []).map(
    v => v.value
  );

  const optionsSet = new Set(selectedOptions);

  const handleSizeClick = useCallback(
    e => {
      e.stopPropagation();
      const value = e.currentTarget.dataset.value;
      if (optionsSet.has(value)) {
        optionsSet.delete(value);
      } else {
        optionsSet.add(value);
      }

      if (optionsSet.size > 0) {
        triggerGA4CustomEvent(
          props.id.toLowerCase(),
          `${props.id.toLowerCase()}:${e.currentTarget.dataset.value.toLowerCase()}`
        );
        setFilter({
          [props.id]: Array.from(optionsSet).join(','),
        });
      } else {
        clearFilters([props.id]);
      }

      if (staticFeatures.injectDynamicYieldScripts && optionsSet.has(value)) {
        dyFilterEvent(props.id, e.currentTarget.dataset.value);
      }

      dispatchAnalyticsEvent({
        event: 'refinement selected',
        params: {
          domevent: props.id,
          domlabel: Array.from(optionsSet).join('|'),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsSet, props.id]
  );

  const handleClearFilter = () => {
    focusOnFirst(props);
    clearFilters([props.id]);
  };

  const xsColumns = Math.min(props.values.length, 4);
  const lgColumns = Math.min(props.values.length, 6);

  return (
    <div className="flex flex-col items-start whitespace-nowrap w-auto">
      {optionsSet.size ? (
        <div>
          <ButtonClearFilter onClick={handleClearFilter} />
        </div>
      ) : null}
      <div
        role="group"
        className={tw(
          'w-full grid gap-2 grid ',
          css({
            gridTemplateColumns: `repeat(${xsColumns}, minmax(4rem, 1fr))`,
          }),
          {
            desktop: css({
              gridTemplateColumns: `repeat(${lgColumns}, minmax(4rem, 1fr))`,
            }),
          }
        )}
        data-test-id={`${props.id}-filter-values`}
      >
        {props.values.map(({ value, label }, idx) => (
          <button
            autoFocus={idx === 0}
            key={value}
            data-value={value}
            data-test-id={`${value}-btn`}
            aria-pressed={optionsSet.has(value)}
            className={`border hover:border-puma-black focus:outline-none text-sm whitespace-nowrap flex items-center justify-center px-2 py-2 ${
              optionsSet.has(value)
                ? 'border-puma-black'
                : 'border-puma-black-500'
            }`}
            onClick={handleSizeClick}
            type="button"
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export const ButtonClearFilter = ({ onClick }) => {
  const t = useTranslate();

  return (
    <button
      className={`mb-5 focus:outline-none
    flex-none relative pl-2 pr-7 py-1 -ml-2 text-puma-black capitalize text-xs
    uppercase tracking-wide font-bold whitespace-nowrap cursor-pointer`}
      onClick={onClick}
      type="button"
    >
      {t('clear')}
      <span
        className="absolute right-2 mt-px w-4 top-2/4 -translate-y-1/2"
        aria-hidden={true}
      >
        <Icon name="close-outline" size="lg" color="muted" />
      </span>
    </button>
  );
};

export const getFilterComponent = ({
  data,
  isFromDrawer,
}: {
  data: FilterOption;
  isFromDrawer: boolean;
}) => {
  switch (data.id) {
    case wellKnownFilterIds.color:
      return <ColorFilter {...data} />;
    case wellKnownFilterIds.size:
      return <SizeFilter {...data} />;
    case wellKnownFilterIds.minMaxPriceRange:
      return <PriceFilterSlider {...data} isFromDrawer={isFromDrawer} />;
    case wellKnownFilterIds.price:
      return <PriceFilter {...data} />;
    default:
      return <CheckboxFilter {...data} />;
  }
};

const dropdownTrigger = style({
  base: 'flex gap-2 items-center h-10 px-3 rounded-sm border hover:border-neutral-100 text-base font-bold focus:outline-none focus-within:(border-neutral-100 ring)',
  variants: {
    enabled: {
      true: 'border-puma-black',
      false: 'border-puma-black-500',
    },
    open: {
      true: '',
    },
  },
});

export const ProductListFilterDropdown = ({
  data,
  openFilter,
  setOpenFilter,
  classNames,
}: {
  data: FilterOption;
  openFilter: string | null;
  setOpenFilter: any;
  classNames?: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setOpen] = useState(false);

  const { enabledFilterOptions } = useProductListFilterContext();

  useDialogDismiss({
    ref: ref,
    external: triggerRef,
    handler: () => setOpenFilter(null),
  });

  useFocusTrap({ ref: ref, isActive: openFilter !== null });

  useEffect(() => {
    if (openFilter !== data.id && isDropdownOpen) {
      triggerRef.current?.focus();
      setOpen(false);
    } else if (data.id === openFilter) {
      setOpen(true);
    }
  }, [setOpen, isDropdownOpen, openFilter, data.id]);

  const hasFilterSelected =
    (enabledFilterOptions[data.id]?.values || []).length > 0 || isDropdownOpen;

  const handleFilterClick = () => {
    if (isDropdownOpen) {
      setOpenFilter(null);
    } else {
      setOpenFilter(data.id);
    }
  };

  return (
    <div
      className={tw(`relative`, classNames)}
      data-test-id={`${data.id}-filter-pill`}
    >
      <button
        ref={triggerRef}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen}
        aria-controls={`${data.id}-filter-dropdown`}
        className={tw(
          'whitespace-nowrap relative',
          dropdownTrigger({
            enabled: hasFilterSelected,
            open: isDropdownOpen,
          })
        )}
        onClick={handleFilterClick}
        type="button"
      >
        {data.label}
        <span className={tw(isDropdownOpen && 'rotate-180')}>
          <Icon name="chevron-down" size="2xl" />
        </span>
      </button>
      {openFilter === data.id && (
        <div
          key={`${data.id}-dropdown`}
          ref={ref}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${data.id}-dropdown-title`}
          tabIndex={-1}
          data-test-id={`${data.id}-filter-dropdown`}
          className={`absolute bottom-0 left-0 transform translate-y-full z-20 whitespace-nowrap focus:outline-none min-w-64`}
        >
          <div
            className={tw(
              'relative bg-white pt-1 shadow-xl border mt-2 border-puma-black-500 text-base',
              {
                desktop: css`
                  max-height: 50vh;
                  overflow-y: auto;
                `,
              }
            )}
          >
            <div className="-mt-1 p-2 text-right flex justify-end">
              <CloseButton onClick={handleFilterClick} size="sm" />
            </div>
            <div className="p-6 pt-0">
              <h3
                data-test-id={`${data.id}-dropdown-title`}
                className="sr-only"
              >
                {data.label} Filter
              </h3>
              {getFilterComponent({ data, isFromDrawer: false })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
