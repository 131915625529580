import React, { useCallback, useRef } from 'react';
import { animation, keyframes, tw } from 'twind/style';
import { useRouter } from 'next/router';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useTranslate } from 'hooks/useTranslations';
import { MAX_PRODUCT_PAGE_SIZE } from 'utils/products';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { AnalyticsEvents, event } from 'utils/analytics';
import { firePreviousAndCurrentGA4ScrollEvents } from 'hooks/useGA4ScrollEvents';

export const LoadMore: React.FC<{
  fetching: boolean;
  isLastPageCurrentlyDisplayed: boolean;
  totalCount: number;
  itemListId?: string;
  itemListName?: string;
}> = props => {
  const router = useRouter();
  const t = useTranslate();
  const ref = useRef<any>();
  const { pageviewEventHasFired, eventHasFired, setEventFired } =
    usePageEventsContext();
  const { itemListId, itemListName } = props;

  const handleLoadMore = useCallback(() => {
    if (pageviewEventHasFired) {
      // fire scroll event 100%
      firePreviousAndCurrentGA4ScrollEvents({
        percentage: '100',
        eventHasFired,
        setEventFired,
      });

      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.SHOW_MORE_ALL,
        event_params: {
          item_list_id: itemListId,
          item_list_name: itemListName,
        },
      });
    }
    router.replace(
      {
        query: {
          ...router.query,
          offset:
            (router.query.offset ? Number(router.query.offset) : 0) +
            MAX_PRODUCT_PAGE_SIZE,
        },
      },
      '',
      { scroll: false }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router.query,
    eventHasFired,
    setEventFired,
    pageviewEventHasFired,
    itemListId,
    itemListName,
  ]);

  useIntersectionObserver(ref, handleLoadMore, true, {
    threshold: 0.1,
    rootMargin: '700px',
  });

  const isFinalPageOfResults =
    (Number(router.query.offset) || 0) + MAX_PRODUCT_PAGE_SIZE >=
    props.totalCount;

  const shouldLoadMoreRefRender =
    props.isLastPageCurrentlyDisplayed && !isFinalPageOfResults;

  return (
    <>
      {props.fetching ? (
        <div
          role="region"
          aria-live="polite"
          aria-atomic
          className="col-span-full"
        >
          <p
            className={tw(
              'mt-8 mb-8 h-16',
              'bg-puma-black text-white border-1 border-puma-black',
              'text-base uppercase font-bold',
              'flex items-center justify-center',
              animation('2s ease infinite', fadeInOut)
            )}
          >
            <span aria-hidden>{t('loading')}</span>
            <span className="sr-only">{t('loadingMoreItems')}</span>
          </p>
        </div>
      ) : (
        shouldLoadMoreRefRender && (
          <div
            className="invisible h-[100px]"
            ref={ref}
            data-test-id="product-list-load-more"
          />
        )
      )}
    </>
  );
};

const fadeInOut = keyframes({
  '0%, 100%': { opacity: '40%' },
  '50%': { opacity: '80%' },
});
