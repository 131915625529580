import { useProductListFilterContext } from 'ui/components/ProductListFilterProvider';

import { useSiteConfig } from './useSiteConfig';

export const useResetConditionsButtonEnabled = () => {
  const { resetSearchConditionsOptions } = useSiteConfig();
  const { enabledFilters } = useProductListFilterContext();

  return !!(
    enabledFilters.length > 0 &&
    resetSearchConditionsOptions?.showResetSearchConditionsButton
  );
};
