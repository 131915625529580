import { useState, useMemo, useRef, useEffect } from 'react';

import {
  ProductSearchHit,
  RecommendationsOutput,
  useSiblingVariantsQuery,
  Variant,
} from '__generated__/graphql';
import { ProductBadges, SaleBadge } from 'ui/components/ProductBadge';
import { useScreenSize } from 'hooks/useScreenSize';

import { ProductPromotions } from './ProductPromotions';
import { ProductListItemImages } from './ProductListItemImages';
import { ProductListItemVariants } from './ProductListItemVariants';
import { ProductListItemRating } from './ProductListItemRating';
import { ProductListItemLink } from './ProductListItemLink';
import { ProductListItemInformation } from './ProductListItemInformation';

type ProductListItemProps = ProductSearchHit & {
  categoryId?: string;
  position: number;
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
  itemListId?: string;
  itemListName?: string;
  search?: string | null;
  onDimensionsChange?: (width: string, height: string) => void;
  isCompactMode?: boolean;
};

export const ProductListItem = (props: ProductListItemProps) => {
  const [variation, setVariation] = useState(props.color);
  const master = props.masterProduct;
  const isCompactMode = props.isCompactMode;
  const [hasInteracted, setHasInteracted] = useState(false);

  const [variantsResult] = useSiblingVariantsQuery({
    variables: { input: { styleNumberId: props.variantProduct?.id } },
    pause:
      master?.orderableColorCount === 1 ||
      !hasInteracted ||
      !props.variantProduct?.id,
    requestPolicy: 'cache-first',
  });

  const variants = variantsResult.data?.siblingVariants ?? [];

  const variant = (variants.find(v => v.colorValue === variation) ??
    props.variantProduct) as Variant;

  // get all but presale
  const soldout = variant?.displayOutOfStock?.soldout;
  const backsoon = variant?.displayOutOfStock?.backsoon;
  const comingsoon = variant?.displayOutOfStock?.comingsoon;
  const hasNotOrderableStatus = soldout || backsoon || comingsoon;

  const promotions = variant?.promotions;
  const badges = variant?.badges;

  const hasBonus = promotions?.length;

  const isAppExclusive = useMemo(
    () => badges?.some(badge => badge.id === 'app-exclusive'),
    [badges]
  );

  const cardRef = useRef<HTMLDivElement | null>(null);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (cardRef && screenSize && props.onDimensionsChange) {
      props.onDimensionsChange(
        `${cardRef?.current?.offsetWidth}px`,
        `${cardRef?.current?.offsetHeight}px`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, cardRef, isCompactMode]);

  return master && variant ? (
    <li
      data-test-id="product-list-item"
      data-product-id={props.variantProduct?.id}
      data-from-recommender={
        props.fromRecommender ? props.fromRecommender.recommenderId : null
      }
      onMouseEnter={() => {
        setHasInteracted(true);
      }}
    >
      <ProductListItemLink
        master={master}
        variant={variant}
        categoryId={props.categoryId}
        fromRecommender={props.fromRecommender}
        position={props.position}
        itemListId={props?.search ? props?.search : props.itemListId}
        itemListName={props?.search ? props?.search : props.itemListName}
      >
        <div
          className="relative w-full flex flex-col gap-2"
          data-primary-product={
            !hasNotOrderableStatus && !isAppExclusive && !hasBonus
          }
          ref={cardRef}
        >
          <ProductListItemImages variant={variant} />
          <ProductListItemVariants
            master={master}
            variations={variants}
            variant={variant}
            select={setVariation}
          />
          <ProductListItemInformation variant={variant} />
          <ProductPromotions variant={variant} compact />
          <ProductBadges variant={variant} />
          <ProductListItemRating master={master} />
          <SaleBadge variant={variant} />
        </div>
      </ProductListItemLink>
    </li>
  ) : null;
};
