import * as SliderPrimitive from '@radix-ui/react-slider';

import { debounce } from 'utils/debounce';

export const THUMB_SIZES = {
  xs: 'h-2 w-2',
  sm: 'h-4 w-4',
  base: 'h-6 w-6',
  lg: 'h-8 w-8',
  xl: 'h-10 w-10',
} as const;

export type ThumbSize = keyof typeof THUMB_SIZES;

export type SliderProps = {
  /**
   * Render data-test-id attribute, used for cypress tests
   */
  dataTestId?: string;
  /**
   * The value of the slider when initially rendered. Use when you do not need to control the state of the slider.
   */
  defaultValue: { min: number; max: number };
  /**
   * The value of the slider when initially rendered. Use when you do not need to control the state of the slider.
   */
  value: { min: number; max: number };
  /**
   * Event handler called when the value changes.
   **/
  onValueChange: (value: number[]) => void;
  /**
   * Event handler called when the value changes at the end of an interaction. Useful when you only need to capture a final value e.g. to update a backend service.
   **/
  onValueCommit: (value: number[]) => void;
  /**
   * When true, prevents the user from interacting with the slider.
   **/
  disabled?: boolean;
  /**
   * The stepping interval.
   **/
  step?: number;
  /**
   * delay in miliseconds to trigger the onValueCommit callback
   **/
  debounceMs?: number;
} & { thumbSize: ThumbSize };

export const Slider = (props: SliderProps) => {
  const thumbStyles = `
  block ${
    THUMB_SIZES[props?.thumbSize || 'base']
  } border-0 border-white bg-puma-black ring-offset-neutral-30 transition-colors
  focus:outline-none focus:ring-1 focus:ring-neutral-70 focus:ring-offset-1 focus:border-1
  ${props.disabled && 'pointer-events-none bg-neutral-70'} !rounded-full`;

  const debounceCallback = debounce(props.onValueCommit, props.debounceMs || 0);

  return (
    <>
      <SliderPrimitive.Root
        style={{ touchAction: 'none' }}
        className="relative flex w-full select-none items-center"
        defaultValue={[props.defaultValue?.min, props.defaultValue?.max]}
        step={props.step || 1}
        minStepsBetweenThumbs={1}
        onValueChange={props.onValueChange}
        onValueCommit={debounceCallback}
        disabled={props.disabled}
        value={[props.value.min, props.value.max]}
        min={props.defaultValue?.min}
        max={props.defaultValue?.max}
      >
        <SliderPrimitive.Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-gray-200">
          <SliderPrimitive.Range
            className={`absolute h-full bg-puma-black ${
              props.disabled && 'bg-neutral-70'
            }`}
          />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className={thumbStyles} />
        <SliderPrimitive.Thumb className={thumbStyles} />
      </SliderPrimitive.Root>
    </>
  );
};
