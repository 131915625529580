import { useHorizontalScroll } from '@global-ecom/nitro-uds/hooks';
import { tw } from 'twind';
import { Icon } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { Product, SiblingVariantsQuery, Variant } from '__generated__/graphql';
import { resizeProductImage } from 'utils/image';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import {
  event,
  AnalyticsEvents,
  addPrefixToAnalyticProp,
} from 'utils/analytics';

import { LeftButton, RightButton } from './DirectionButton';
import { Skeleton } from './Skeleton';

export const ProductListItemVariants = (props: {
  master: Product;
  variations: SiblingVariantsQuery['siblingVariants'];
  variant: Variant;
  select: (id: string) => void;
}) => {
  const t = useTranslate();
  const variantImageContainer = useHorizontalScroll({ infinite: false });
  const numberOfVariants =
    props.master.orderableColorCount || props.variations?.length || 1;

  const moreThanOneVariant = numberOfVariants > 1;

  const { pageviewEventHasFired } = usePageEventsContext();

  return (
    <div
      className="relative h-12 mobile:h-10 flex items-center group"
      data-test-id="colors-hover-data-test-id"
      onClick={event => {
        if (moreThanOneVariant) {
          // prevent click events from triggering the parent link
          event.preventDefault();
        }
      }}
    >
      <span
        className={tw(
          moreThanOneVariant && 'group-hover:hidden group-focus-within:hidden',
          'text-puma-black-300 uppercase text-xs whitespace-nowrap flex items-center'
        )}
      >
        {numberOfVariants} {t(moreThanOneVariant ? 'colors' : 'color')}
        {moreThanOneVariant && (
          <Icon
            className="sm:hidden ml-1 text-neutral-60"
            name="chevron-down"
            size="base"
          />
        )}
      </span>
      {moreThanOneVariant && (
        <div
          className={tw(
            'invisible group-hover:visible group-focus-within:visible flex relative w-full items-center justify-center z-[5]'
          )}
        >
          <div
            role="group"
            ref={variantImageContainer.ref}
            className="flex w-8/12 overflow-x-auto scroll-snap !no-scrollbars"
            aria-label={t(
              `colorVariation${moreThanOneVariant && 's'}` as never
            )}
          >
            {Array.from({ length: numberOfVariants }).map((_, idx) => {
              const variation = props.variations?.[idx];

              if (!variation) {
                return (
                  <Skeleton
                    key={idx}
                    className="w-1/4 flex-none last-child:mr-auto first-child:ml-auto aspect-1-1 rounded-none"
                    delay={`${(idx + 1) * 100}ms`}
                  />
                );
              }

              return (
                <button
                  key={idx}
                  aria-pressed={
                    variation.colorValue === props.variant.colorValue
                  }
                  aria-label={`${t('preview')} ${variation.colorName}`}
                  className="w-1/4 flex-none last-child:mr-auto first-child:ml-auto"
                  onClick={() => {
                    props.select(variation.colorValue);
                    if (pageviewEventHasFired) {
                      event(AnalyticsEvents.GA4_CustomEvent, {
                        event_name: 'swatch_click',
                        event_params: {
                          item_id_ep: variation.id,
                          item_name_ep: variation.name,
                          color_code_ep: addPrefixToAnalyticProp(
                            variation.colorValue
                          ),
                        },
                      });
                    }
                  }}
                  type="button"
                >
                  <img
                    alt={`${variation.name} - ${variation.colorName}`}
                    className={tw(
                      'block w-full border',
                      variation.colorValue === props.variant?.colorValue
                        ? 'border-neutral-70'
                        : 'border-white'
                    )}
                    loading="lazy"
                    width="100"
                    height="100"
                    src={resizeProductImage(
                      variation.images[0]?.href,
                      100
                    ).replace('fafafa', 'ffffff')}
                  />
                </button>
              );
            })}
          </div>
          <LeftButton
            onClick={variantImageContainer.scrollToPrev}
            disabled={!variantImageContainer.hasPrev}
            label={t('scrollLeft')}
          />
          <RightButton
            onClick={variantImageContainer.scrollToNext}
            disabled={!variantImageContainer.hasNext}
            label={t('scrollRight')}
          />
        </div>
      )}
    </div>
  );
};
