import React from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { useProductListFilterContext } from 'ui/components/ProductListFilterProvider';

import { Button } from './Button';

export const ResetSearchConditionsButton = () => {
  const t = useTranslate();
  const { clearAllFilters } = useProductListFilterContext();

  return (
    <Button
      variant="black"
      height="tall"
      className="px-10"
      type="button"
      onClick={clearAllFilters}
    >
      {t('resetSearchConditions')}
      <span className="sr-only">{t('resetSearchConditions')}</span>
    </Button>
  );
};
