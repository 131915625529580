import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  Breadcrumb as UdsBreadcrumb,
  BreadcrumbProps,
  Icon,
} from '@global-ecom/nitro-uds/elements';
import { stripHtml } from 'string-strip-html';

import { removeHyphens } from 'utils/removeHyphens';
import { capitalize } from 'utils/capitalize';
import { getIsDesktop } from 'utils/media';
import { FITANALYTICS_IS_LOADED_INDICATOR, isServer } from 'utils/constants';
import { useTranslate } from 'hooks/useTranslations';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { InjectedScript } from 'ui/elements/InjectedScript';
import { useBreadcrumbUrlRedirects } from 'hooks/useBreadcrumbUrlRedirects';

import { getUrlFromLocale } from '../../utils/locale';

export const Breadcrumb: React.FC<{
  path: BreadcrumbProps['path'];
  isPdpFromPlp?: boolean;
}> = ({ path, isPdpFromPlp }) => {
  const router = useRouter();
  const {
    host,
    locale,
    absoluteLinkBreadcrumb,
    staticFeatures: { isPNARegion },
  } = useSiteConfig();
  const t = useTranslate();

  const protocol = isServer ? 'https:' : window.location.protocol;
  const baseUrlLink = `${protocol}//${host}${getUrlFromLocale(locale)}`;
  const baseUrl = absoluteLinkBreadcrumb ? baseUrlLink : '';

  const pdpUrlRegex = /^[a-z]{2}\/[a-z]{2}\/pd\/.*\/\\d{6,}/;
  const isPdp = pdpUrlRegex.test(router.pathname);

  // Build links array
  const links = useMemo(() => {
    return path
      .filter(pathElement => pathElement.url !== '/puma')
      .map(({ label, url }) => {
        const displayLabel = isPdp
          ? removeHyphens(label)
          : label === 'home'
          ? t('home')
          : label;

        const fullUrl = `${baseUrl}/${encodeURI(
          url.length && url[0] === '/' ? url.slice(1) : url
        )}`;

        return {
          label: displayLabel,
          url: fullUrl,
        };
      });
  }, [path, isPdp, t, host, locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const { redirections, fetchRedirections } = useBreadcrumbUrlRedirects(links);

  useEffect(() => {
    if (isPNARegion && links.length > 0) {
      fetchRedirections();
    }
  }, [isPNARegion, links.length, fetchRedirections]);

  const prevToLastLink = links[links.length - 2] || links[0];
  const showMobilePdpBreadcrumb = !getIsDesktop() && isPdpFromPlp;

  const removeTrailingSlash = (url: string) => {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  };

  return (
    <>
      {showMobilePdpBreadcrumb ? (
        <button
          type="button"
          className="flex items-center font-bold"
          onClick={() => router.back()}
        >
          <div className="mt-0.5 mr-1">
            <Icon size="sm" name="chevron-left" />
          </div>
          {prevToLastLink.label}
        </button>
      ) : (
        <UdsBreadcrumb
          {...FITANALYTICS_IS_LOADED_INDICATOR}
          path={redirections ?? links}
          className="-ml-1.5"
        />
      )}

      <InjectedScript
        type="application/ld+json"
        script={JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'BreadcrumbList',
          itemListElement: links.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: capitalize(stripHtml(item.label).result),
            item: item.url.startsWith('http')
              ? item.url
              : removeTrailingSlash(`${baseUrlLink}${item.url}`),
            logo: `https://${host}/assets/logo-puma-black.png`,
          })),
        })}
      />
    </>
  );
};
