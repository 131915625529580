import { useEffect, useState } from 'react';

import { useWindowSize } from './useWindowSize';

enum Size {
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
}

export const useScreenSize = () => {
  const { width } = useWindowSize();
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    if (width !== null) {
      let size: Size = Size.SMALL;

      if (width < 640) size = Size.SMALL;
      else if (width < 1024) size = Size.MEDIUM;
      else size = Size.LARGE;

      setScreenSize(size);
    }
  }, [width]);

  return screenSize;
};
