import { Client } from 'urql';

import {
  CategoryByIdQuery,
  QueryUrlRedirectV2Args,
} from '__generated__/graphql';
import {
  URL_REDIRECT_QUERY,
  UrlRedirectQueryResult,
} from 'gql/queries/urlRedirect';

import { constructCategoryPath } from './constructCategoryPath';

export type Link = {
  label: string;
  url: string;
  isAbsoluteUrl?: boolean;
};

/**
 * Creates an array of breadcrumb links from a category
 * @param currentCategory - The category data
 * @returns Array of breadcrumb links
 */
export const createCategoryPageBreadcrumbLinks = (
  currentCategory?: CategoryByIdQuery['categoryById'] | null
): Link[] => {
  const categoryPath = constructCategoryPath(currentCategory);
  return [{ label: 'home', url: '' }, ...categoryPath];
};

/**
 * Creates breadcrumb links specifically for product detail pages
 * @param params - Object containing necessary data for product breadcrumbs
 * @returns Array of raw breadcrumb links ready for display
 */
export const createProductBreadcrumbLinks = ({
  categoryById,
  productName,
  productLink,
  isCustomizing = false,
  customizeUrl = '',
  customizeLabel = 'customize',
  isPersonalizing = false,
  personalizeLabel = 'personaliseBreadcrumb',
}: {
  categoryById: any;
  productName?: string;
  productLink?: string;
  isCustomizing?: boolean;
  customizeUrl?: string;
  customizeLabel?: string;
  isPersonalizing?: boolean;
  personalizeLabel?: string;
}): Link[] => {
  const categoryLinks = createCategoryPageBreadcrumbLinks(categoryById);

  const links = [...categoryLinks];

  if (productName && productLink) {
    links.push({
      label: productName,
      url: productLink,
      isAbsoluteUrl: true,
    });
  }

  if (isCustomizing && customizeUrl) {
    links.push({
      label: customizeLabel,
      url: customizeUrl,
    });
  }

  if (isPersonalizing) {
    links.push({
      label: personalizeLabel,
      url: '',
    });
  }

  return links;
};

/**
 * Gets the links that need to be checked for redirects
 * @param links - The full breadcrumb links array
 * @returns Array of links to check for redirects
 */
export const getLinksToCheck = (links: Link[]): Link[] => {
  // Skip first and last elements as per useBreadcrumbUrlRedirects implementation
  return links.slice(1, -1);
};

/**
 * Fetches URL redirect queries for breadcrumb links
 * @param client - The URQL client
 * @param links - The links to check for redirects
 * @returns Promise with the results of the redirect queries
 */
export const fetchBreadcrumbRedirectPromises = (
  client: Client,
  links: Link[]
) => {
  const linksToCheck = getLinksToCheck(links);

  // Create URL redirect queries for each breadcrumb link
  return linksToCheck.map(({ url }) => {
    return client.query<UrlRedirectQueryResult, QueryUrlRedirectV2Args>(
      URL_REDIRECT_QUERY,
      {
        path: url.startsWith('/') ? url.slice(1) : url,
      }
    );
  });
};
