/**
 * Map color filter label values to match all regions
 */
export const mapLabel = (label: string): string => {
  switch (label) {
    case 'multi-colored':
      return 'Multi-Colored';
    case 'Assorted Colours':
      return 'Colored';
    case 'Beige':
      return 'Tan';
    default:
      return label;
  }
};
