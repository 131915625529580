import React from 'react';
import { tw } from 'twind';
import { Icon } from '@global-ecom/nitro-uds/elements';

import { Maybe } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { scrollTo } from 'utils/scroll-to';
import { isNumber } from 'utils/isNumber';

type StarRatingProps = {
  dataTestId?: string;
  rating?: Maybe<number | string>;
  pageAnchorId?: string;
};

export const StarRating = (props: StarRatingProps) => {
  const rating = Number(props.rating);

  return isNumber(rating) ? (
    <>
      {!props.pageAnchorId && <StarRatingSRLabel rating={rating} />}
      <div
        role="presentation"
        className="flex items-center text-puma-black-500"
        data-test-id={props.dataTestId}
      >
        <div className="flex relative">
          {[...Array(5)].map((_, i) => (
            <Icon
              key={`first-rating-${i}`}
              name="star-empty"
              className="flex-shrink-0"
              size="2xl"
            />
          ))}
          <div
            className={tw(
              'flex absolute overflow-hidden text-puma-black top-0',
              `w-[${Math.round((rating / 5) * 100)}%]`
            )}
          >
            {[...Array(5)].map((_, i) => (
              <Icon
                key={`second-rating-${i}`}
                name="star-filled"
                className="flex-shrink-0"
                size="2xl"
              />
            ))}
          </div>
          {props.pageAnchorId && (
            <a
              href={`#${props.pageAnchorId}`}
              className="absolute top-0 w-[100%] h-[100%]"
              onClick={e => {
                e.preventDefault();
                if (props.pageAnchorId) {
                  scrollTo(props.pageAnchorId, -100);
                }
              }}
            >
              <StarRatingSRLabel rating={rating} />
            </a>
          )}
        </div>
      </div>
    </>
  ) : null;
};

const StarRatingSRLabel = (props: { rating: number }) => {
  const t = useTranslate();

  return (
    <span className="sr-only">
      {t('ratingAverage')}: {props.rating.toFixed(1)} / 5
    </span>
  );
};
