import {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
} from 'react';

import { focusOnFirst } from 'ui/components/ProductListFilterDropdown';
import { FilterOption } from '__generated__/graphql';

import { extractNumbersFromString } from './extractNumbersFromString';
import { wellKnownFilterIds } from './products';
import { removeLetters } from './removeLetters';

export type PriceRange = {
  min: string;
  max: string;
};

export const formatDefaultPriceRange = (defaultPriceRange: string): number[] =>
  defaultPriceRange
    ?.replace('(', '')
    .replace(')', '')
    .split('..')
    .map(price => Number(price));

export const getDefaultPriceRange = (data: FilterOption): PriceRange => {
  const defaultPriceRange = data?.values[data?.values?.length - 1]?.value || '';
  const [min, max] = formatDefaultPriceRange(defaultPriceRange);
  return {
    min: String(Math.floor(min)),
    max: String(Math.ceil(max)),
  };
};

export const addCurrencySymbol = (
  price: string,
  currencySymbol: string
): string => {
  // we need to remove the letters from the currency symbol to avoid a price format like CAD$ and to extract only the symbol $
  const formattedCurrencySymbol = removeLetters(currencySymbol);
  return `${formattedCurrencySymbol} ${price}`;
};

type setPriceInputError = {
  inputName: 'min' | 'max';
  setPriceRange: Dispatch<SetStateAction<PriceRange>>;
  defaultPriceRange: PriceRange;
  priceRange: PriceRange;
  ref: MutableRefObject<NodeJS.Timeout | null>;
  delay: number;
};

export const setPriceInputError = ({
  defaultPriceRange,
  delay,
  inputName,
  priceRange,
  ref,
  setPriceRange,
}: setPriceInputError) => {
  if (ref?.current) {
    clearTimeout(ref?.current);
  }
  const setDefaultPriceRange = () => {
    setPriceRange({
      ...priceRange,
      [inputName]: defaultPriceRange[inputName],
    });
  };

  ref.current = setTimeout(() => {
    setDefaultPriceRange();
  }, delay);
};

export const handleClearFilter = (
  props: PropsWithChildren<FilterOption>,
  clearFilters: (filters: string[]) => void
) => {
  focusOnFirst(props);
  clearFilters(['minPrice', 'maxPrice']);
};

export const handlePriceInputFocus = (
  inputRef: React.RefObject<HTMLInputElement>,
  symbol: string
) => {
  // we need to add some delay to execute this logic after the component renders when focousing the input
  setTimeout(() => {
    if (inputRef.current) {
      // Set the value to only the currency symbol
      inputRef.current.value = removeLetters(symbol);
      // Place the cursor at the end of the currency symbol
      inputRef.current.setSelectionRange(1, 1);
    }
  }, 0);
};

//this function will remove the Price filter when there is a minMaxPriceRange filter present and the feature flag is enabled, otherwise it will remove the minMaxPriceRange if exist.
export const removePriceFilterIfMinMaxExists = (
  items: FilterOption[],
  isPriceFilterSliderEnabled: boolean | undefined
): FilterOption[] => {
  const minMaxPriceRangeEnabled =
    items.some(item => item.id === wellKnownFilterIds.minMaxPriceRange) &&
    isPriceFilterSliderEnabled;

  if (minMaxPriceRangeEnabled) {
    return items.filter(item => item.id !== wellKnownFilterIds.price);
  } else
    return items.filter(
      item => item.id !== wellKnownFilterIds.minMaxPriceRange
    );
};

export function validatePriceFilterLabel(str1: string, str2: string) {
  if (!str1 || !str2 || typeof str1 !== 'string' || typeof str2 !== 'string')
    return false;

  return extractNumbersFromString(str1) === extractNumbersFromString(str2);
}

export const paramsOrPriceRange_Min = ({
  queryParamsPriceRange,
  priceRange,
}: {
  queryParamsPriceRange: number[];
  priceRange: PriceRange;
}) => queryParamsPriceRange?.[0] || Number(priceRange.min || 0);

export const paramsOrPriceRange_Max = ({
  queryParamsPriceRange,
  priceRange,
}: {
  queryParamsPriceRange: number[];
  priceRange: PriceRange;
}) => queryParamsPriceRange?.[1] || Number(priceRange.max || 0);

type Ga4SliderPosition = {
  slider_low: string;
  slider_high: string;
  slider_range: string;
};

export const get_GA4_SliderPosition = (
  minPrice: string,
  maxPrice: string,
  defaultPriceRange: PriceRange
): Ga4SliderPosition => {
  // The result from the difference is then divided by 10 to get the value of each interval from 1 to 10
  const defaultValueSegment =
    (Number(defaultPriceRange.max) - Number(defaultPriceRange.min)) / 10;

  // records the low position as number in a range between 0-10
  const slider_low = Math.round(
    (Number(minPrice) - Number(defaultPriceRange.min)) / defaultValueSegment
  );
  // records the low position as number in a range between 0-10
  const slider_high = Math.round(
    (Number(maxPrice) - Number(defaultPriceRange.min)) / defaultValueSegment
  );
  //
  return {
    slider_low: String(slider_low),
    slider_high: String(slider_high),
    slider_range: String(slider_high - slider_low),
  };
};
