import { useCallback, useState } from 'react';
import { useClient } from 'urql';

import { UrlRedirect } from '__generated__/graphql';
import { isContentOnline } from 'utils/isContentOnline';
import {
  Link,
  fetchBreadcrumbRedirectPromises,
} from 'utils/breadcrumbRedirects';

const isRedirectActive = (redirect: UrlRedirect): boolean => {
  const { availableFrom, availableTo } = redirect;
  const isAvailable = isContentOnline(
    {
      onlineFrom: availableFrom,
      onlineTo: availableTo,
    },
    {}
  );
  if (!isAvailable || !redirect.url) return false;
  return true;
};

export const useBreadcrumbUrlRedirects = (links: Link[]) => {
  const client = useClient();
  const [redirections, setRedirections] = useState<Link[] | undefined>(
    undefined
  );

  const fetchRedirections = useCallback(async () => {
    try {
      const breadcrumbPromises = fetchBreadcrumbRedirectPromises(client, links);
      const results = await Promise.all(breadcrumbPromises);

      const updatedPaths: Link[] = results.map(({ data }, idx) =>
        data && isRedirectActive(data.urlRedirectV2)
          ? { label: links[idx + 1].label, url: data.urlRedirectV2.url! }
          : { ...links[idx + 1] }
      );

      if (updatedPaths.length > 0) {
        setRedirections([
          { ...links[0] },
          ...updatedPaths,
          { ...links[links.length - 1] },
        ]);
      }
    } catch (e) {}
  }, [client, links]);

  return { fetchRedirections, redirections };
};
