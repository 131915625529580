import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DotAnimation, InputText } from '@global-ecom/nitro-uds/elements';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { debounce } from 'utils/debounce';
import { FilterOption } from '__generated__/graphql';
import {
  addCurrencySymbol,
  get_GA4_SliderPosition,
  handleClearFilter,
  handlePriceInputFocus,
  paramsOrPriceRange_Max,
  paramsOrPriceRange_Min,
  PriceRange,
  setPriceInputError,
} from 'utils/priceFilter';
import { dyFilterEvent } from 'utils/dynamicYield';
import { extractNumbersFromString } from 'utils/extractNumbersFromString';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { AnalyticsEvents, event } from 'utils/analytics';

import { Slider } from '../../UDS/elements/action-cta/Slider';

import { ButtonClearFilter } from './ProductListFilterDropdown';
import { useProductListFilterContext } from './ProductListFilterProvider';

export type PriceFilterType = FilterOption & { isFromDrawer: boolean };

const PriceFilterSlider: React.FC<PriceFilterType> = props => {
  const {
    enabledFilterOptions,
    setFilter,
    clearFilters,
    priceRange,
    setPriceRange,
    defaultPriceRange,
    isFetching,
    queryParamsPriceRange,
    totalCount,
  } = useProductListFilterContext();
  const { staticFeatures, currency, priceSliderInterval, priceInputMaxLength } =
    useSiteConfig();

  const { pageviewEventHasFired } = usePageEventsContext();

  const validatingPriceRangeScope = useRef(false);
  const [isTyping, setIsTyping] = useState(false);

  const selectedValue =
    enabledFilterOptions['price']?.values[0]?.value || undefined;

  const minInputRef = useRef<HTMLInputElement>(null);
  const maxInputRef = useRef<HTMLInputElement>(null);
  const debouncedHandleSubmit = debounce(e => {
    handleSubmit(e);
    setIsTyping(false);
  }, 2000);

  const handleInputDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const handleSubmit = useCallback(
    (e: PriceRange) => {
      const minPrice = e?.min;
      const maxPrice = e?.max;

      const { slider_low, slider_high, slider_range } = get_GA4_SliderPosition(
        minPrice,
        maxPrice,
        defaultPriceRange
      );

      if (!validatingPriceRangeScope.current && pageviewEventHasFired) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.GA4EC_Refinement,
          event_params: {
            refinement_attribute: 'price',
            refinement_selected: 'undefined',
            price_low: minPrice,
            price_high: maxPrice,
            min_price: defaultPriceRange.min,
            max_price: defaultPriceRange.max,
            slider_low: slider_low,
            slider_high: slider_high,
            slider_range: slider_range,
            slider_currency: currency.code,
          },
        });
      }

      if (staticFeatures.injectDynamicYieldScripts) {
        const filterProps = `pmax:${maxPrice},pmin:${minPrice}`;
        dyFilterEvent('price', filterProps);
      }

      setFilter({ minPrice, maxPrice });
    },
    [
      currency.code,
      defaultPriceRange,
      pageviewEventHasFired,
      setFilter,
      staticFeatures.injectDynamicYieldScripts,
    ]
  );

  // kept this enun here to a best readability
  enum inputKeys {
    max = 'max',
    min = 'min',
  }
  const minDefaultPriceRange = Number(defaultPriceRange.min || 0);
  const maxDefaultPriceRange = Number(defaultPriceRange.max || 0);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const value = extractNumbersFromString(rawValue); // This extracts numbers only
    const inputKey = e.target.name as inputKeys;

    // Range validation with numeric comparison
    const isOutOfTheDefaultRange =
      value < minDefaultPriceRange || value > maxDefaultPriceRange;
    const isInputError =
      (inputKey === inputKeys.min && value >= Number(priceRange.max || 0)) ||
      (inputKey === inputKeys.max && value <= Number(priceRange.min || 0)) ||
      isOutOfTheDefaultRange;

    if (!isNaN(value) && value >= 0) {
      let newPriceRange = { ...priceRange };
      setPriceRange({ ...priceRange, [inputKey]: String(value) });

      // if there's a wrong value, set the default input value to be submitted
      if (isInputError) {
        newPriceRange = {
          ...priceRange,
          [inputKey]: defaultPriceRange[inputKey],
        };
        setPriceInputError({
          inputName: inputKey,
          setPriceRange,
          delay: 3000,
          defaultPriceRange,
          priceRange,
          ref: handleInputDebounceRef,
        });
      } else {
        // Clear any pending debounced error trigger since the value is correct
        if (handleInputDebounceRef.current) {
          clearTimeout(handleInputDebounceRef.current);
        }
        newPriceRange = { ...priceRange, [inputKey]: value };
      }

      debouncedHandleSubmit(newPriceRange);
    }
  };

  // this will update the price range values when the default price rance is changed when another filter is applied (color, size) to keep the values inside of the defaultRange.
  useEffect(() => {
    if (isTyping) return;
    validatingPriceRangeScope.current = true;
    const priceMin = paramsOrPriceRange_Min({
      queryParamsPriceRange,
      priceRange,
    });
    const priceMax = paramsOrPriceRange_Max({
      queryParamsPriceRange,
      priceRange,
    });

    const paramsOrPriceMaxOutOfScope =
      priceMax > Number(defaultPriceRange.max || 0);
    const paramsOrPriceMinOutOfScope =
      priceMin < Number(defaultPriceRange.min || 0);

    if (paramsOrPriceMaxOutOfScope && paramsOrPriceMinOutOfScope) {
      // if the priceSelected.max is out of the default.max && the priceSelected.min is out of the default.min
      const newHandleSubmitValues = {
        min: defaultPriceRange.min,
        max: defaultPriceRange.max,
      };
      if (queryParamsPriceRange) handleSubmit(newHandleSubmitValues);
      else setPriceRange(newHandleSubmitValues);
    }
    // if the priceSelected.max is out of the default.max
    else if (paramsOrPriceMaxOutOfScope) {
      const newHandleSubmitValues = {
        min: priceRange.min || defaultPriceRange.min,
        max: defaultPriceRange.max,
      };
      if (queryParamsPriceRange) handleSubmit(newHandleSubmitValues);
      else setPriceRange(newHandleSubmitValues);
    }

    // if the priceSelected.min is out of the default.min
    else if (paramsOrPriceMinOutOfScope) {
      const newHandleSubmitValues = {
        max: priceRange.max || defaultPriceRange.max,
        min: defaultPriceRange.min,
      };
      if (queryParamsPriceRange) handleSubmit(newHandleSubmitValues);
      else setPriceRange(newHandleSubmitValues);
    }
    validatingPriceRangeScope.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange, defaultPriceRange, handleSubmit]);

  return (
    <div
      className={`flex flex-col w-full ${
        props.isFromDrawer ? 'w-full pb-4' : 'w-[336px] pb-2'
      } `}
    >
      {isFetching && !props.isFromDrawer && (
        <div className="top-0 right-0 bg-white z-40 opacity-30 absolute w-full h-full">
          <DotAnimation
            on={true}
            className="absolute left-1/2 top-8 transform -translate-1/2"
          />
        </div>
      )}
      {selectedValue || totalCount === 0 ? (
        <div>
          <ButtonClearFilter
            onClick={() => handleClearFilter(props, clearFilters)}
          />
        </div>
      ) : null}

      <div
        className="space-y-3 items-center"
        data-test-id={`${props.id}-filter-values`}
      >
        <div className="flex gap-3 mb-8">
          <InputText
            label=""
            ref={minInputRef}
            name={inputKeys.min}
            maxLength={priceInputMaxLength + 2} // we add 2 more spaces for the currency symbol and the bsp.
            value={addCurrencySymbol(priceRange.min, currency.symbol)}
            onChange={e => handleInputChange(e)}
            onFocus={() => {
              setIsTyping(true);
              handlePriceInputFocus(minInputRef, currency.symbol);
            }}
            onBlur={() => setIsTyping(false)}
          />
          <InputText
            label=""
            ref={maxInputRef}
            name={inputKeys.max}
            maxLength={priceInputMaxLength + 2}
            value={addCurrencySymbol(priceRange.max, currency.symbol)}
            onChange={e => handleInputChange(e)}
            onFocus={() => {
              setIsTyping(true);
              handlePriceInputFocus(maxInputRef, currency.symbol);
            }}
            onBlur={() => setIsTyping(false)}
          />
        </div>
        <Slider
          defaultValue={{
            min: minDefaultPriceRange,
            max: maxDefaultPriceRange,
          }}
          onValueChange={e =>
            setPriceRange({ min: String(e[0]), max: String(e[1]) })
          }
          onValueCommit={e =>
            handleSubmit({ min: String(e[0]), max: String(e[1]) })
          }
          step={priceSliderInterval}
          value={{
            min: Number(priceRange.min || 0),
            max: Number(priceRange.max || 0),
          }}
          thumbSize={props.isFromDrawer ? 'lg' : 'base'}
          disabled={isFetching}
          debounceMs={1000}
        />
      </div>
    </div>
  );
};

export default PriceFilterSlider;
