import { useSiteConfig } from 'hooks/useSiteConfig';
import { Variant } from '__generated__/graphql';

import { ProductPrice, PLP_PRICE_STRATEGY } from './ProductPrice/ProductPrice';

export const ProductListItemInformation = (props: { variant: Variant }) => {
  const {
    staticFeatures: { showHeaderSubHeaderProductDetail },
  } = useSiteConfig();
  return (
    <div className="relative flex mobile:flex-col w-full items-start">
      <div className="flex flex-col w-full">
        <h3 className="w-full mobile:text-sm mobile:pr-0 font-bold text-base pr-5 line-clamp-2">
          {showHeaderSubHeaderProductDetail
            ? props.variant.header
            : props.variant.name}
          <span className="sr-only">{props.variant.colorName}</span>
        </h3>
        {showHeaderSubHeaderProductDetail && props.variant.subHeader && (
          <span className="w-full mobile:text-sm mobile:pr-0 text-base text-puma-gray-60 line-clamp-2">
            {props.variant.subHeader}
          </span>
        )}
      </div>
      <div className="flex flex-col flex-none mobile:items-start items-end text-sm md:text-base mobile:mt-2">
        <ProductPrice
          price={{
            amount: props.variant.productPrice?.price,
            salePrice: props.variant.productPrice?.salePrice,
            promotionPrice: props.variant.productPrice?.promotionPrice,
            isSalePriceElapsed: props.variant.productPrice?.isSalePriceElapsed,
          }}
          compact
          {...PLP_PRICE_STRATEGY}
        />
      </div>
    </div>
  );
};
